import { ReactElement, ReactNode } from "react";
import { ClipboardDocumentCheckIcon, DocumentArrowDownIcon, SquaresPlusIcon } from "@heroicons/react/24/outline";
import AssessmentReportHome from "@/screens/AssessmentReportHome";
import AssessmentReportSingle from "@/screens/AssessmentReportSingle";
import CreateAssessmentReport from "@/screens/CreateAssessmentReport";
import DocumentsScreen from "@/screens/DocumentsScreen";
import StimulusEditScreen from "@/screens/stimulus/StimulusEditScreen";
import StimulusHomeScreen from "@/screens/stimulus/StimulusHomeScreen";
import StimulusUploadScreen from "@/screens/stimulus/StimulusUploadScreen";
import { TruncatedUser, UserRole } from "@/store/user.store";

export type Route = {
  id: string;
  path: string;
  screen: ReactNode;
  friendlyName: string;
  isExternalLink?: boolean;
  icon?: ReactElement;
  isHidden?: boolean;
  canAccess?: (user: TruncatedUser) => boolean;
};

export const reportHomeRoute = {
  id: "assessment-report-home",
  path: "/assessment/report",
  screen: <AssessmentReportHome />,
  friendlyName: "Reports",
  icon: <ClipboardDocumentCheckIcon />,
};

export const protocolRoute = {
  id: "protocols",
  path: "/protocols",
  screen: <DocumentsScreen />,
  friendlyName: "Protocols",
  icon: <DocumentArrowDownIcon />,
};

const canAccessStimulus = ({ role, email }: TruncatedUser) =>
  role === UserRole.Coordinator || import.meta.env.VITE_ALLOW_STIMULUS_EMAILS?.includes(email) || false;

export const allRoutes: Route[] = [
  reportHomeRoute,
  {
    id: "assessment-report-single",
    path: "/assessment/report/:id",
    screen: <AssessmentReportSingle />,
    friendlyName: "Single Report",
  },
  protocolRoute,
  {
    id: "stimulus",
    path: "/stimulus",
    screen: <StimulusHomeScreen />,
    friendlyName: "Stimulus",
    icon: <SquaresPlusIcon />,
    canAccess: canAccessStimulus,
  },
  {
    id: "stimulus-edit",
    path: "/stimulus/book/:bookId/edit",
    screen: <StimulusEditScreen />,
    friendlyName: "Edit Stimulus Book",
    canAccess: canAccessStimulus,
  },
  {
    id: "stimulus-upload",
    path: "/stimulus/upload",
    screen: <StimulusUploadScreen />,
    friendlyName: "Upload Stimuli",
    canAccess: canAccessStimulus,
  },
  {
    id: "create-report",
    path: "/assessment/report/new",
    friendlyName: "Create Assessment Report",
    screen: <CreateAssessmentReport />,
    isHidden: true,
  },
];
