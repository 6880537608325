import { useContext } from "react";
import {
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
  AssessmentTestData,
} from "@parallel/vertex/types/assessment/assessment.testing.types";
import { UpdateTextInput } from "@/components/common/elements/input/UpdateTextInput";
import { StimulusStoreContext } from "@/store";
import { TestGroup } from "@/store/stimulus.store";
import EditTestAudioForm from "./EditTestAudioForm";
import { ImageType } from "./EditTestImageMargin";

const MISSING_NAME_PLACEHOLDER = "MISSING_NAME";

export const isDisplayGroup = (g: TestGroup): g is AssessmentDisplayGroupData => !isConfigGroup(g);

export const isConfigGroup = (g: TestGroup): g is AssessmentConfigGroupData => "clientStimulus" in g;

const EditTest = ({
  test,
  nextTest,
  prevTest,
  startImageMarginEdit,
}: {
  test: AssessmentTestData;
  nextTest?: AssessmentTestData;
  prevTest?: AssessmentTestData;
  startImageMarginEdit: (t: ImageType) => void;
}) => {
  const stimulusStore = useContext(StimulusStoreContext);
  const { configGroupsByTestId, displayGroupsByTestId, flatDisplayGroups, orderedConfigGroups, selectedGroup } =
    stimulusStore;

  const currConfigGroup = configGroupsByTestId[test.id];
  const currDisplayGroup = displayGroupsByTestId[test.id];

  const updateTest = (t: Partial<AssessmentTestData>) => stimulusStore.updateTests([{ ...t, id: test.id }]);

  const updateName = (newValue: string) => {
    if (test.displayName === newValue || newValue === MISSING_NAME_PLACEHOLDER) return;
    updateTest({ displayName: newValue });
  };

  const updateImage = (key: "client" | "staff") => (newValue: string) => {
    if (test.image[key] === newValue) return;
    updateTest({ image: { ...test.image, [key]: newValue } });
  };

  const displayGroupLabelClick = () => {
    if (selectedGroup && isDisplayGroup(selectedGroup))
      stimulusStore.assignTestToDisplayGroup(test.id, selectedGroup, currDisplayGroup);
  };

  const configGroupLabelClick = () => {
    if (selectedGroup && isConfigGroup(selectedGroup))
      stimulusStore.assignTestToConfigGroup(test.id, selectedGroup, currConfigGroup);
  };

  const swapOrder = async (swapTest: AssessmentTestData) => {
    const currOrderIndex = test.orderIndex;
    stimulusStore.updateTests([
      { id: test.id, orderIndex: swapTest.orderIndex },
      { id: swapTest.id, orderIndex: currOrderIndex },
    ]);
  };

  return (
    <div className="p-3 my-4 border border-gray-300 rounded bg-gray-100">
      <div className="w-full font-semibold">Name</div>
      <UpdateTextInput
        initialValue={test.displayName || test.name || MISSING_NAME_PLACEHOLDER}
        persist={updateName}
        className="text-lg w-full mb-2"
      />

      <div className="w-full mt-2 font-semibold">Client Image</div>
      <UpdateTextInput initialValue={test.image.client || ""} persist={updateImage("client")} className="w-full" />
      {test.image.client && (
        <button
          onClick={() => startImageMarginEdit(ImageType.Client)}
          className="px-1 mt-1 rounded-md border border-gray-400 bg-slate-200 text-sm"
        >
          Edit Margins
        </button>
      )}

      <div className="w-full mt-4 font-semibold">Staff Image</div>
      <UpdateTextInput initialValue={test.image.staff} persist={updateImage("staff")} className="w-full" />
      {test.image.staff && (
        <button
          onClick={() => startImageMarginEdit(ImageType.Staff)}
          className="px-1 mt-1 rounded-md border border-gray-400 bg-slate-200 text-sm"
        >
          Edit Margins
        </button>
      )}

      <EditTestAudioForm test={test} />

      <button onClick={displayGroupLabelClick} className="mt-4 font-semibold">
        Display Group
      </button>
      <br />
      <select
        value={currDisplayGroup?.id}
        onChange={e => stimulusStore.assignTestToDisplayGroupId(test.id, e.target.value, currDisplayGroup)}
        className="max-w-full py-1 px-2 border border-gray-200 rounded"
      >
        <option value={""}>-- None --</option>
        {flatDisplayGroups.map(g => (
          <option value={g.id} key={g.id}>
            {g.parent ? `${g.parent.name} -- ${g.name}` : g.name}
          </option>
        ))}
      </select>
      <br />

      <button onClick={configGroupLabelClick} className="mt-2 font-semibold">
        Config Group
      </button>
      <br />
      <select
        value={currConfigGroup?.id}
        onChange={e => stimulusStore.assignTestToConfigGroupId(test.id, e.target.value, currConfigGroup)}
        className="max-w-full py-1 px-2 border border-gray-200 rounded"
      >
        <option value={""}>-- None --</option>
        {orderedConfigGroups.map(g => (
          <option value={g.id} key={g.id}>
            {g.name}
          </option>
        ))}
      </select>
      <br />

      <div className="mt-4">
        <button
          onClick={() => stimulusStore.deleteTest(test.id)}
          className="border border-gray-400 bg-slate-200 p-1 rounded-md mr-2"
        >
          Delete
        </button>
        {prevTest && (
          <button
            onClick={() => swapOrder(prevTest)}
            className="border border-gray-400 bg-slate-200 p-1 rounded-md mr-2"
          >
            Bump Up
          </button>
        )}
        {nextTest && (
          <button onClick={() => swapOrder(nextTest)} className="border border-gray-400 bg-slate-200 p-1 rounded-md">
            Bump Down
          </button>
        )}
      </div>
    </div>
  );
};

export default EditTest;
