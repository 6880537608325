import { MouseEventHandler, MouseEvent, ReactNode, useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import {
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
} from "@parallel/vertex/types/assessment/assessment.testing.types";
import { StimulusStoreContext } from "@/store";
import { TestGroup } from "@/store/stimulus.store";
import { classNames } from "@/utils";

type GroupListProps<G extends TestGroup> = {
  groups: G[];
  selectedGroup?: TestGroup;
};

type CommonGroupListProps<G extends TestGroup> = GroupListProps<G> & {
  addGroup: (name: string) => void;
  updateGroup: (g: G) => void;
  deleteGroup: (g: G) => void;
};

type GroupProps<G extends TestGroup> = CommonGroupListProps<G> & {
  group: G;
  index: number;
};

const Button = ({ children, onClick }: { children: ReactNode; onClick: MouseEventHandler<HTMLButtonElement> }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick(e);
    e.stopPropagation();
  };
  return (
    <button onClick={handleClick} className="border border-gray-400 bg-slate-200 px-1 rounded-md mr-2">
      {children}
    </button>
  );
};

const _GroupListItem = <G extends TestGroup>({ group, index, groups, updateGroup, deleteGroup }: GroupProps<G>) => {
  const stimulusStore = useContext(StimulusStoreContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(group.name);
  const inputRef = useRef<HTMLInputElement>(null!);

  const startUpdatingName = () => {
    setIsEditing(true);
    inputRef.current?.focus();
  };

  const updateName = () => {
    group.name = editName;
    updateGroup(group);
    setIsEditing(false);
  };

  const orderSwap = (otherGroup?: G): (() => void) | undefined => {
    if (!otherGroup || isUndefined(group.orderIndex) || isUndefined(otherGroup?.orderIndex)) return;
    return () => {
      const newIndex = otherGroup.orderIndex;
      otherGroup.orderIndex = group.orderIndex;
      group.orderIndex = newIndex;
      updateGroup(group);
      updateGroup(otherGroup);
    };
  };
  const moveGroupUp = orderSwap(groups[index - 1]);
  const moveGroupDown = orderSwap(groups[index + 1]);

  const isSelected = group.id === stimulusStore.selectedRootGroupId;

  return (
    <div
      onClick={() => stimulusStore.selectRootGroup(group.id)}
      className={classNames(
        "w-full h-20 cursor-pointer border bg-gray-100 rounded px-2 mb-2",
        isSelected ? "border-ocean bg-white" : "border-gray-300",
      )}
    >
      {isEditing ? (
        <input
          value={editName}
          onBlur={updateName}
          onChange={e => setEditName(e.target.value)}
          ref={inputRef}
          className="text-lg mt-2 w-full"
        />
      ) : (
        <p
          className={classNames(
            "text-lg mt-2 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis",
            isSelected ? "font-semibold" : "",
          )}
        >
          {group.name}
        </p>
      )}
      <div className="mt-1">
        {moveGroupUp && <Button onClick={moveGroupUp}>Up</Button>}
        {moveGroupDown && <Button onClick={moveGroupDown}>Down</Button>}
        <Button onClick={startUpdatingName}>Rename</Button>
        <Button onClick={() => deleteGroup(group)}>Delete</Button>
      </div>
    </div>
  );
};

const GroupListItem = observer(_GroupListItem);

const GroupList = <G extends TestGroup>(props: CommonGroupListProps<G>) => {
  const { groups, addGroup } = props;
  const newGroupForm = useForm<{ name: string }>();
  const submitNewDisplayGroup = ({ name }: { name: string }) => {
    addGroup(name);
    newGroupForm.reset();
  };
  return (
    <>
      {groups.map((g: G, i: number) => (
        <GroupListItem group={g} index={i} key={g.id} {...props} />
      ))}
      <form onSubmit={newGroupForm.handleSubmit(submitNewDisplayGroup)}>
        <input {...newGroupForm.register("name")} className="border border-black rounded-md mr-2 mt-2 p-1" />
        <button type="submit">Create</button>
      </form>
    </>
  );
};

export const DisplayGroupList = (props: GroupListProps<AssessmentDisplayGroupData>) => {
  const stimulusStore = useContext(StimulusStoreContext);
  return (
    <GroupList
      {...props}
      addGroup={name => stimulusStore.createDisplayGroup(name)}
      updateGroup={g => stimulusStore.updateDisplayGroup(g.id, g)}
      deleteGroup={g => stimulusStore.deleteDisplayGroup(g.id)}
    />
  );
};

export const ConfigGroupList = (props: GroupListProps<AssessmentConfigGroupData>) => {
  const stimulusStore = useContext(StimulusStoreContext);
  return (
    <GroupList
      {...props}
      addGroup={name => stimulusStore.createConfigGroup(name)}
      updateGroup={g => stimulusStore.updateConfigGroup(g.id, g)}
      deleteGroup={g => stimulusStore.deleteConfigGroup(g.id)}
    />
  );
};
