import { useContext, useEffect, useState } from "react";
import { findIndex } from "lodash";
import { observer } from "mobx-react-lite";
import { AssessmentBookMetadata, AssessmentTestData } from "@parallel/vertex/types/assessment/assessment.testing.types";
import Modal from "@/components/common/content/Modal";
import { StimulusStoreContext } from "@/store";
import EditTest from "./EditTest";
import EditTestImageMargin, { ImageMarginProps, ImageType } from "./EditTestImageMargin";

const getAdjacentMarginEdits = (
  curr: ImageMarginProps,
  allTests: AssessmentTestData[],
): { next?: ImageMarginProps; prev?: ImageMarginProps } => {
  const nextTestIndex = curr.testIndex + 1;
  const nextTest = allTests[nextTestIndex];

  const prevTestIndex = curr.testIndex - 1;
  const prevTest = allTests[prevTestIndex];

  let prev, next;
  if (curr.type === ImageType.Client) {
    next = {
      ...curr,
      type: ImageType.Staff,
    };
    prev = prevTest
      ? {
          test: prevTest,
          testIndex: prevTestIndex,
          type: ImageType.Staff,
        }
      : undefined;
  } else {
    next = nextTest
      ? {
          test: nextTest,
          testIndex: nextTestIndex,
          type: nextTest.image.client ? ImageType.Client : ImageType.Staff,
        }
      : undefined;
    prev = curr.test.image.client
      ? {
          ...curr,
          type: ImageType.Client,
        }
      : prevTest
        ? {
            test: prevTest,
            testIndex: prevTestIndex,
            type: ImageType.Staff,
          }
        : undefined;
  }
  return { next, prev };
};

const TestList = ({ book }: { book: AssessmentBookMetadata }) => {
  const stimulusStore = useContext(StimulusStoreContext);
  const { tests } = book;

  const [marginEdit, setMarginEdit] = useState<ImageMarginProps>();
  useEffect(() => {
    if (!marginEdit) return;

    const testIndex = findIndex(tests, t => t.id === marginEdit.test.id);
    const test = tests[testIndex];
    setMarginEdit(test && { test, testIndex, type: marginEdit.type });
  }, [tests]);

  const { next: nextMarginEdit, prev: prevMarginEdit } = marginEdit
    ? getAdjacentMarginEdits(marginEdit, tests)
    : { next: undefined, prev: undefined };

  const [deletingTest, setDeletingTest] = useState<AssessmentTestData>();

  return (
    <>
      {tests.map((t, i) => (
        <EditTest
          test={t}
          nextTest={tests[i + 1]}
          prevTest={tests[i - 1]}
          startImageMarginEdit={type => setMarginEdit({ test: t, testIndex: i, type })}
          key={t.id}
        />
      ))}
      {marginEdit && (
        <Modal
          title={`${marginEdit.type} Image -- ${marginEdit.test.name}`}
          containerStyle={{ width: "80%", height: "90%" }}
          onClose={() => setMarginEdit(undefined)}
        >
          <EditTestImageMargin
            {...marginEdit}
            selectNext={nextMarginEdit ? () => setMarginEdit(nextMarginEdit) : undefined}
            selectPrevious={prevMarginEdit ? () => setMarginEdit(prevMarginEdit) : undefined}
          />
        </Modal>
      )}
      {deletingTest && (
        <Modal
          title="Confirm Test Delete"
          onSubmit={async () => {
            await stimulusStore.deleteTest(deletingTest.id);
            setDeletingTest(undefined);
          }}
          onClose={() => setDeletingTest(undefined)}
          closeButton={true}
          closeButtonText="Cancel"
          submitButtonText="Delete"
        >
          <>Deleting test {deletingTest.displayName || deletingTest.name} - this operation cannot be undone</>
        </Modal>
      )}
    </>
  );
};

export default observer(TestList);
