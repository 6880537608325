import { keyBy, max, orderBy, sortBy, uniq } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import {
  AssessmentBookMetadata,
  AssessmentBookRawData,
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
  UpdateAssessmentTest,
} from "@parallel/vertex/types/assessment/assessment.testing.types";
import { filterExists, mapExists } from "@parallel/vertex/util/collection.util";
import { StimulusAPI } from "@/api/stimulus.api";

export type NamedAssessmentBookMetadata = AssessmentBookMetadata & { name: string };

export type ConfigGroup = AssessmentConfigGroupData;
export type DisplayGroup = AssessmentDisplayGroupData & { parent?: AssessmentDisplayGroupData };
export type TestGroup = DisplayGroup | ConfigGroup;

export class StimulusStore {
  books?: AssessmentBookRawData[];
  activeBook?: NamedAssessmentBookMetadata;

  selectedRootGroupId?: string;
  selectedSubGroupId?: string;

  constructor(private stimulusApi: StimulusAPI) {
    makeAutoObservable(this);
  }

  get orderedDisplayGroups(): AssessmentDisplayGroupData[] {
    return sortBy(this.activeBook?.displayGroups, "orderIndex");
  }

  get flatDisplayGroups(): DisplayGroup[] {
    return this.orderedDisplayGroups.flatMap(parentGroup => [parentGroup, ...this.parseSubgroups(parentGroup)]);
  }

  get displayGroupsByTestId(): Record<string, DisplayGroup> {
    return this.flatDisplayGroups.reduce<Record<string, DisplayGroup>>(
      (curr, g) => ({
        ...curr,
        ...g.testIds.reduce<Record<string, AssessmentDisplayGroupData>>((ids, tid) => ({ ...ids, [tid]: g }), {}),
      }),
      {},
    );
  }

  get orderedConfigGroups(): ConfigGroup[] {
    return sortBy(this.activeBook?.configGroups, "orderIndex");
  }

  get configGroupsByTestId(): Record<string, ConfigGroup> {
    return this.orderedConfigGroups.reduce<Record<string, AssessmentConfigGroupData>>(
      (curr, g) => ({
        ...curr,
        ...g.testIds.reduce<Record<string, AssessmentConfigGroupData>>((ids, tid) => ({ ...ids, [tid]: g }), {}),
      }),
      {},
    );
  }

  get testGroupsById() {
    return keyBy([...this.flatDisplayGroups, ...this.orderedConfigGroups], "id");
  }

  private findGroup(groupId?: string): TestGroup | undefined {
    if (!groupId) return;
    return this.testGroupsById[groupId];
  }

  get selectedRootGroup(): TestGroup | undefined {
    return this.findGroup(this.selectedRootGroupId);
  }

  get selectedGroup(): TestGroup | undefined {
    return this.findGroup(this.selectedSubGroupId) || this.selectedRootGroup;
  }

  parseSubgroups = (parent: DisplayGroup): DisplayGroup[] =>
    orderBy(parent.subgroups, "orderIndex").map((subgroup, i) => ({ ...subgroup, id: `${parent.id}.${i}`, parent }));

  selectRootGroup(groupId: string) {
    this.selectedRootGroupId = groupId;
    this.setSelectedSubGroupId(undefined);
  }

  setSelectedSubGroupId(groupId: string | undefined) {
    this.selectedSubGroupId = groupId;
  }

  async fetchRawBooks() {
    const books = await this.stimulusApi.getAllRawBooks();
    runInAction(() => (this.books = books));
    return books;
  }

  async setBookHidden(bookId: string, isHidden: boolean) {
    const updatedBook = await this.stimulusApi.setBookHidden(bookId, isHidden);
    runInAction(() => (this.books = this.books?.map(b => (b.id === bookId ? updatedBook : b))));
    return updatedBook;
  }

  async selectBook(bookId: string) {
    const allBooks = this.books || (await this.fetchRawBooks());
    const rawBook = allBooks.find(b => b.id === bookId);
    if (!rawBook) return;
    const metadata = await this.stimulusApi.getBookMetadata(bookId);
    runInAction(() => (this.activeBook = { ...metadata, name: rawBook.name }));
  }

  async createBook(book: AssessmentBookRawData) {
    const createdBook = await this.stimulusApi.createBook(book);
    runInAction(() => this.books?.push(createdBook));
  }

  async updateTests(updates: UpdateAssessmentTest[]) {
    return this.updateBook(({ bookId }) => this.stimulusApi.updateTests(bookId, updates));
  }

  async updateTest(update: UpdateAssessmentTest) {
    return this.updateTests([update]);
  }

  async deleteTest(testId: string) {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteTest(bookId, testId));
  }

  async createDisplayGroup(name: string): Promise<AssessmentDisplayGroupData | undefined> {
    return this.updateBookGroups(
      ({ bookId, displayGroups }) => {
        const orderIndex = (max(displayGroups.map(g => g.orderIndex)) || -1) + 1;
        return this.stimulusApi.createDisplayGroup(bookId, { name, orderIndex });
      },
      (currBook, newGroup) => ({ displayGroups: [...currBook.displayGroups, newGroup] }),
    );
  }

  async createConfigGroup(name: string): Promise<AssessmentConfigGroupData | undefined> {
    return this.updateBookGroups(
      ({ bookId, configGroups }) => {
        const orderIndex = (max(configGroups.map(g => g.orderIndex)) || -1) + 1;
        return this.stimulusApi.createConfigGroup(bookId, { name, orderIndex });
      },
      (currBook, newGroup) => ({ configGroups: [...currBook.configGroups, newGroup] }),
    );
  }

  async updateDisplayGroup(
    groupId: string,
    update: Partial<AssessmentDisplayGroupData>,
  ): Promise<AssessmentDisplayGroupData | undefined> {
    return this.updateBookGroups(
      ({ bookId }) => this.stimulusApi.updateDisplayGroup(bookId, groupId, update),
      (currBook, updatedGroup) => ({
        displayGroups: orderBy(
          currBook.displayGroups.map(g => (g.id === update.id ? updatedGroup : g)),
          "orderIndex",
        ),
      }),
    );
  }

  async updateConfigGroup(
    groupId: string,
    update: Partial<AssessmentConfigGroupData>,
  ): Promise<AssessmentConfigGroupData | undefined> {
    return this.updateBookGroups(
      ({ bookId }) => this.stimulusApi.updateConfigGroup(bookId, groupId, update),
      (currBook, updatedGroup) => ({
        configGroups: orderBy(
          currBook.configGroups.map(g => (g.id === update.id ? updatedGroup : g)),
          "orderIndex",
        ),
      }),
    );
  }

  async assignTestToConfigGroup(testId: string, addGroup: ConfigGroup, removeGroup?: ConfigGroup): Promise<void> {
    this.updateBook(async currBook => {
      const { bookId, configGroups } = currBook;
      const updatedGroups = await Promise.all([
        this.stimulusApi.updateConfigGroup(bookId, addGroup.id, { testIds: uniq([...addGroup.testIds, testId]) }),
        removeGroup &&
          this.stimulusApi.updateConfigGroup(bookId, removeGroup.id, {
            testIds: removeGroup.testIds.filter(id => id !== testId),
          }),
      ]).then(filterExists);
      return {
        ...currBook,
        configGroups: configGroups.map(g => updatedGroups.find(u => u.id === g.id) || g),
      };
    });
  }

  async assignTestToDisplayGroup(testId: string, addGroup: DisplayGroup, removeGroup?: DisplayGroup): Promise<void> {
    this.updateBook(async currBook => {
      const { bookId, displayGroups } = currBook;

      const addUpdate = { testIds: uniq([...addGroup.testIds, testId]) };
      const addPromise = !addGroup.parent
        ? this.stimulusApi.updateDisplayGroup(bookId, addGroup.id, addUpdate)
        : this.stimulusApi.updateDisplayGroup(bookId, addGroup.parent.id, {
            subgroups: mapExists(addGroup.parent.subgroups, g =>
              g.name === addGroup.name ? { ...g, ...addUpdate } : g,
            ),
          });

      let removePromise: Promise<DisplayGroup | null> = Promise.resolve(null);
      if (removeGroup) {
        const removeUpdate = { testIds: removeGroup.testIds.filter(id => id !== testId) };
        removePromise = !removeGroup.parent
          ? this.stimulusApi.updateDisplayGroup(bookId, removeGroup.id, removeUpdate)
          : this.stimulusApi.updateDisplayGroup(bookId, removeGroup.parent.id, {
              subgroups: mapExists(removeGroup.parent.subgroups, g =>
                g.name === removeGroup.name ? { ...g, ...removeUpdate } : g,
              ),
            });
      }

      const updatedGroups = await Promise.all([addPromise, removePromise]).then(filterExists);
      return {
        ...currBook,
        displayGroups: displayGroups.map(g => updatedGroups.find(u => u.id === g.id) || g),
      };
    });
  }

  async assignTestToConfigGroupId(testId: string, addGroupId: string, removeGroup?: ConfigGroup): Promise<void> {
    const addGroup = this.orderedConfigGroups.find(g => g.id === addGroupId);
    if (!addGroup) return;
    return this.assignTestToConfigGroup(testId, addGroup, removeGroup);
  }

  async assignTestToDisplayGroupId(testId: string, addGroupId: string, removeGroup?: DisplayGroup): Promise<void> {
    const addGroup = this.flatDisplayGroups.find(g => g.id === addGroupId);
    if (!addGroup) return;
    return this.assignTestToDisplayGroup(testId, addGroup, removeGroup);
  }

  async deleteDisplayGroup(groupId: string) {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteDisplayGroup(bookId, groupId));
  }

  async deleteConfigGroup(groupId: string) {
    return this.updateBook(({ bookId }) => this.stimulusApi.deleteConfigGroup(bookId, groupId));
  }

  private updateBook = async (fn: (currBook: AssessmentBookMetadata) => Promise<AssessmentBookMetadata>) => {
    if (!this.activeBook) return;
    const currBook = this.activeBook;
    const updatedBook = await fn(currBook);
    runInAction(() => (this.activeBook = { ...currBook, ...updatedBook }));
    return updatedBook;
  };

  private updateBookGroups = async <G>(
    fn: (currBook: AssessmentBookMetadata) => Promise<G>,
    updateBook: (currBook: AssessmentBookMetadata, result: G) => Partial<AssessmentBookMetadata>,
  ): Promise<G | undefined> => {
    if (!this.activeBook) return;
    const currBook = this.activeBook;
    const result = await fn(currBook);
    runInAction(() => (this.activeBook = { ...currBook, ...updateBook(currBook, result) }));
    return result;
  };
}
