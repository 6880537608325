import { difference, isEmpty, ListIteratee, omit, pick, remove } from "lodash";
import { action, makeAutoObservable, runInAction } from "mobx";
import { ReportValueGroup, ReportDocSection } from "@parallel/vertex/enums/report.enums";
import { HydratedEligibility } from "@parallel/vertex/types/report.guidance.types";
import { AssessmentReport, ReportEligibility, HydratedAssessmentReport } from "@parallel/vertex/types/report.types";
import { reduceAsyncSequence, processSequence } from "@parallel/vertex/util/async.util";
import { InterviewFormType, ReportAPI } from "@/api/report.api";
import { ReportDocumentAPI } from "@/api/report.document.api";
import { ReportGuidanceAPI } from "@/api/report.guidance.api";
import { ConfirmAction, ConfirmSpec } from "@/components/common/content/ConfirmModal";
import { ToggleItem } from "@/components/common/windows/MultiSelectWindow";
import { toHeaderString } from "@/utils/assessment";
import { ClientLogger } from "@/utils/logging.utils";
import { AlertStore } from "./alert.store";

export const REPORT_CONFIG_FIELDS = ["infoSourceKeys", "testInterpretations"];

export type HydratedReportEligibility = {
  canonical: HydratedEligibility;
  report: ReportEligibility;
};

export enum ReportBuildStatus {
  NotRunning = "not-running",
  Building = "building",
  Finished = "finished",
}

type CriteriaItems = {
  token: string;
  items: ToggleItem[];
  isValid: boolean;
};

export type RemoteReportUpdaterParams = {
  name: string;
  fn: () => Promise<AssessmentReport>;
  sideEffect?: (r: AssessmentReport) => void;
  confirmSpec?: ConfirmSpec;
  updateLocalConfig?: boolean;
};

const AllReportDocSections = [
  ReportDocSection.InfoSourceTable,
  ReportDocSection.Interpretations,
  ReportDocSection.Eligibility,
  ReportDocSection.Recommendations,
  ReportDocSection.Appendix,
];

type ReportBuildResultAttempts<A> = {
  pending: A[];
  success: A[];
  failures: { attempt: A; error: any }[];
};

type ReportBuildResult = {
  startedAt: Date;
  finishedAt?: Date;
  interviewForms: ReportBuildResultAttempts<InterviewFormType>;
  values: ReportBuildResultAttempts<ReportValueGroup>;
  content: ReportBuildResultAttempts<ReportDocSection>;
  status?: string;
};

export class ReportStore {
  hydratedReport?: HydratedAssessmentReport | null = undefined;
  reportEligibilities?: HydratedReportEligibility[] | null = undefined;

  isUpdating: boolean = false;
  reportBuildStatus: ReportBuildStatus = ReportBuildStatus.NotRunning;
  confirmAction?: ConfirmAction = undefined;

  buildResult?: ReportBuildResult = undefined;

  constructor(
    private alertStore: AlertStore,
    private reportApi: ReportAPI,
    private reportDocumentApi: ReportDocumentAPI,
    private reportGuidanceApi: ReportGuidanceAPI,
    private logger: ClientLogger,
  ) {
    makeAutoObservable(this);
  }

  get report() {
    return this.hydratedReport?.report;
  }

  /**
   * Map every eligibility id enabled on the report to a list of objects corresponding with the criteria list of that eligibility.
   * Each criteria list object includes
   *  1. a list of individual criteria w/ a boolean indicating if it's enabled on the report, and
   *  2. a boolean indicating if the list is "valid" for building - determined by if at least one criteria in the list is selected
   */
  get availableEligibilityCriteriaItems(): Partial<Record<string, CriteriaItems[]>> {
    if (!this.reportEligibilities) return {};
    return this.reportEligibilities.reduce((currItems, nextEligibility) => {
      const itemLists = nextEligibility.canonical.criteriaSets
        .map(l => {
          const items = l.criteria.map(c => ({
            key: c.id,
            name: c.text,
            isEnabled: nextEligibility.report.enabledCriteriaIds.includes(c.id),
          }));
          if (items.length === 0) return null;
          return {
            token: l.token,
            items,
            isValid: !!items.find(c => c.isEnabled),
          };
        })
        .filter(Boolean) as CriteriaItems[];
      return {
        ...currItems,
        [nextEligibility.canonical.id]: itemLists,
      };
    }, {});
  }

  // can build doc if report eligibilities have been fetched
  // and all report eligibility criteria lists are valid
  get areEligibilitiesValid() {
    if (!this.reportEligibilities) return false;
    const allCriteriaItems = this.availableEligibilityCriteriaItems;
    return !this.reportEligibilities.find(d => this.isEligibilityValid(d.canonical.id, allCriteriaItems));
  }

  get areTestInterpretationsValid() {
    if (!this.report) return false;
    if (!this.report.testInterpretations?.length) return true;
    return this.report.testInterpretations?.some(i => i.isEnabled || i.subsections?.some(s => s.isEnabled));
  }

  get areMeasuresUsedValid() {
    if (!this.report || !this.report.infoSourceKeys) return false;
    return this.report.infoSourceKeys.length > 0;
  }

  get cannotBuildReportMessage() {
    if (!this.report) return "";
    if (!this.report.formSubmissionIds && !this.areMeasuresUsedValid)
      return "Must select at least one measure used before building";
    if (!this.areTestInterpretationsValid) return "Must select at least one test interpretation before building";
    if (!this.areEligibilitiesValid) return "Must select at least one eligibility criteria per list before building";
  }

  // eligibility is valid if all of its criteria lists have at least one criteria selected on the report
  isEligibilityValid(
    eligibilityId: string,
    allCriteriaItems: Partial<Record<string, CriteriaItems[]>> = this.availableEligibilityCriteriaItems,
  ) {
    const eligibilityCriteriaItems = allCriteriaItems[eligibilityId];
    return !eligibilityCriteriaItems || !!eligibilityCriteriaItems.find(items => !items.isValid);
  }

  async fetchReport(reportId: string) {
    try {
      const report = await this.logger.wrapOperation("fetchReport", this.reportApi.get(reportId), { reportId });
      runInAction(() => (this.hydratedReport = report));
    } catch (_error) {
      // TODO indicate this fetch error better
      runInAction(() => (this.hydratedReport = null));
    }
    this.syncReportEligibilities();
  }

  async resolveReportEligibilityRecords(): Promise<HydratedEligibility[]> {
    const reportEligibilityIds = this.hydratedReport?.report?.eligibilities?.map(d => d.eligibilityId) || [];
    const canonicalEligibilities = this.reportEligibilities?.map(d => d.canonical) || [];

    // if there are no new eligibility records to fetch, just return all known records referenced by the report
    const missingRecordEligibilityIds = difference(
      reportEligibilityIds,
      canonicalEligibilities.map(d => d.id),
    );
    if (missingRecordEligibilityIds.length === 0) {
      return canonicalEligibilities.filter(d => reportEligibilityIds.includes(d.id));
    }

    const fetchedEligibilityRecords = await this.reportGuidanceApi.getEligibilitiesByIds(reportEligibilityIds);
    return fetchedEligibilityRecords;
  }

  async syncReportEligibilities() {
    try {
      const reportEligibilities = this.hydratedReport?.report?.eligibilities || [];
      const canonicalEligibilities = await this.resolveReportEligibilityRecords();
      runInAction(() => {
        this.reportEligibilities = canonicalEligibilities.flatMap(canonical => {
          const report = reportEligibilities.find(rd => rd.eligibilityId === canonical.id);
          return !report ? [] : [{ report, canonical }];
        });
      });
    } catch (e) {
      this.logger.error("error fetching report eligibilities", e);
      // TODO indicate this fetch error better
      runInAction(() => (this.reportEligibilities = null));
    }
  }

  setLocalReport(update: Partial<AssessmentReport>) {
    if (!this.hydratedReport) return;
    this.hydratedReport = { ...this.hydratedReport, report: { ...this.hydratedReport.report, ...update } };
    this.syncReportEligibilities();
  }

  performUpdate<A>(name: string, fn: () => Promise<A>, onSuccess?: (a: A) => void) {
    this.isUpdating = true;
    return fn()
      .then(r => onSuccess && onSuccess(r))
      .catch(e => this.alertStore.setFailedProcess(name, e))
      .finally(action(() => (this.isUpdating = false)));
  }

  async updateRemoteReport({ name, fn, sideEffect, confirmSpec, updateLocalConfig }: RemoteReportUpdaterParams) {
    const performUpdate = () =>
      this.performUpdate(name, fn, report => {
        sideEffect && sideEffect(report);
        const localUpdate = updateLocalConfig ? report : omit(report, REPORT_CONFIG_FIELDS);
        this.setLocalReport({ ...this.report, ...localUpdate });
      });

    if (confirmSpec)
      runInAction(() => {
        this.confirmAction = {
          action: performUpdate,
          name,
          spec: confirmSpec,
        };
      });
    else await performUpdate();
  }

  clearConfirmAction() {
    this.confirmAction = undefined;
  }

  setReport(report: AssessmentReport) {
    if (!this.hydratedReport) return;
    this.hydratedReport = {
      ...this.hydratedReport,
      report,
    };
  }

  updateResultAttempts<A extends ListIteratee<A>>(group: ReportBuildResultAttempts<A>, attempt: A, error?: any) {
    const updated = { ...group };
    remove(updated.pending, attempt);
    if (error) {
      updated.failures.push({ attempt, error });
    } else {
      updated.success.push(attempt);
    }
    return updated;
  }

  interviewFormInjectFinished(formType: InterviewFormType, logContext: any, error?: any) {
    if (!this.buildResult) return;
    const updatedValues = this.updateResultAttempts(this.buildResult.interviewForms, formType, error);
    this.buildResult.interviewForms = updatedValues;
    this.logger.operationResult("injectInterviewForm", { ...logContext, formType }, error);
  }

  valueInjectFinished(group: ReportValueGroup, logContext: any, error?: any) {
    if (!this.buildResult) return;
    const updatedValues = this.updateResultAttempts(this.buildResult.values, group, error);
    this.buildResult.values = updatedValues;
    this.logger.operationResult("injectValues", { ...logContext, group }, error);
  }

  sectionBuildFinished(section: ReportDocSection, logContext: any, error?: any) {
    if (!this.buildResult) return;
    this.buildResult.content = this.updateResultAttempts(this.buildResult.content, section, error);
    this.logger.operationResult("buildSection", { ...logContext, section }, error);
  }

  async buildIncrementally({ isRebuild = false }: { isRebuild?: boolean } = {}) {
    const report = this.report;
    if (!report) return;

    const injectInterviewFormTypes = Object.keys(report.formSubmissionIds?.interview || {}) as InterviewFormType[];

    const pendingValueGroups = Object.keys(report.pendingValues || {}) as ReportValueGroup[];
    const injectValueGroups = isRebuild
      ? [...pendingValueGroups, ...(Object.keys(report.injectedValues || {}) as ReportValueGroup[])]
      : pendingValueGroups;

    const buildSections = [...AllReportDocSections];

    runInAction(() => {
      this.reportBuildStatus = ReportBuildStatus.Building;
      this.buildResult = {
        startedAt: new Date(),
        status: "creating new doc",
        interviewForms: { pending: [...injectInterviewFormTypes], success: [], failures: [] },
        values: { pending: [...injectValueGroups], success: [], failures: [] },
        content: { pending: [...buildSections], success: [], failures: [] },
      };
    });

    const performFullBuild = async () => {
      const logContext = { reportId: report.id };
      const docReport = await this.logger.wrapOperation(
        "buildDoc",
        this.reportDocumentApi.buildDoc(report.id, pick(report, REPORT_CONFIG_FIELDS)),
        logContext,
      );
      this.setReport(docReport);

      runInAction(() => {
        if (this.buildResult) this.buildResult.status = "performing token presence updates";
      });
      await this.logger.wrapOperation(
        "performTokenPresenceUpdates",
        this.reportDocumentApi.performTokenPresenceUpdates(report.id),
        logContext,
      );

      const builtReport = await this.buildGuidanceSections(docReport, logContext);

      // inject data from interview form submissions one form at a time in sequence
      await processSequence(injectInterviewFormTypes, formType => {
        runInAction(() => {
          if (this.buildResult) this.buildResult.status = `injecting data from ${formType} interview form`;
        });
        return this.reportDocumentApi
          .injectInterviewFormValues(report.id, formType)
          .then(() => this.interviewFormInjectFinished(formType, logContext))
          .catch(e => this.interviewFormInjectFinished(formType, logContext, e));
      });

      // inject report values one value group at a time in sequence
      const injectedReport = await reduceAsyncSequence(
        injectValueGroups,
        (group, report) => {
          runInAction(() => {
            if (this.buildResult) this.buildResult.status = `injecting data from group ${toHeaderString(group)}`;
          });
          return this.reportDocumentApi.injectValues(report.id, group);
        },
        builtReport,
        {
          onSuccess: group => this.valueInjectFinished(group, logContext),
          onFailure: (group, error) => this.valueInjectFinished(group, logContext, error),
        },
      );
      this.setReport(injectedReport);

      const finishedReport = await reduceAsyncSequence(
        [ReportDocSection.InfoSourceTable, ReportDocSection.Interpretations, ReportDocSection.Appendix],
        (section, report) => {
          runInAction(() => {
            if (this.buildResult) this.buildResult.status = `updating doc ${toHeaderString(section)}`;
          });
          return this.reportDocumentApi.buildSection(report.id, section);
        },
        injectedReport,
        {
          onSuccess: section => this.sectionBuildFinished(section, logContext),
          onFailure: (section, error) => this.sectionBuildFinished(section, logContext, error),
        },
      );
      this.setReport(finishedReport);

      runInAction(() => {
        if (this.buildResult) {
          this.buildResult = {
            ...this.buildResult,
            status: undefined,
            finishedAt: new Date(),
          };
        }
        this.reportBuildStatus = ReportBuildStatus.Finished;
      });
      return injectedReport;
    };

    this.updateRemoteReport({
      name: "build report doc",
      fn: performFullBuild,
      updateLocalConfig: true,
    });
  }

  async reinjectValuesIncrementally() {
    const report = this.report;
    if (!report) return;

    const injectInterviewFormTypes = Object.keys(report.formSubmissionIds?.interview || {}) as InterviewFormType[];
    const pendingValueGroups = Object.keys(report.pendingValues || {}) as ReportValueGroup[];
    runInAction(() => {
      this.buildResult = {
        startedAt: new Date(),
        interviewForms: { pending: [...injectInterviewFormTypes], success: [], failures: [] },
        values: { pending: [...pendingValueGroups], success: [], failures: [] },
        content: { pending: [], success: [], failures: [] },
      };
      this.reportBuildStatus = ReportBuildStatus.Building;
    });

    const performReinject = async () => {
      const logContext = { reportId: report.id, isReinject: true };
      runInAction(() => {
        if (this.buildResult) this.buildResult.status = "performing token presence updates";
      });
      await this.logger.wrapOperation(
        "performTokenPresenceUpdates",
        this.reportDocumentApi.performTokenPresenceUpdates(report.id),
        logContext,
      );

      const builtReport = await this.buildGuidanceSections(report, logContext);

      // inject data from interview form submissions one form at a time in sequence
      await processSequence(injectInterviewFormTypes, formType => {
        runInAction(() => {
          if (this.buildResult) this.buildResult.status = `injecting data from ${formType} interview form`;
        });
        return this.reportDocumentApi
          .injectInterviewFormValues(report.id, formType)
          .then(() => this.interviewFormInjectFinished(formType, logContext))
          .catch(e => this.interviewFormInjectFinished(formType, logContext, e));
      });

      const injectedReport = await reduceAsyncSequence(
        pendingValueGroups,
        (group, report) => {
          runInAction(() => {
            if (this.buildResult) this.buildResult.status = `injecting data from group ${toHeaderString(group)}`;
          });
          return this.reportDocumentApi.injectValues(report.id, group);
        },
        builtReport,
        {
          onSuccess: group => this.valueInjectFinished(group, logContext),
          onFailure: (group, error) => this.valueInjectFinished(group, logContext, error),
        },
      );
      this.setReport(injectedReport);
      runInAction(() => {
        if (this.buildResult) {
          this.buildResult = {
            ...this.buildResult,
            status: undefined,
            finishedAt: new Date(),
          };
        }
        this.reportBuildStatus = ReportBuildStatus.Finished;
      });
      return injectedReport;
    };

    this.updateRemoteReport({
      name: "inject new values",
      fn: performReinject,
    });
  }

  private buildGuidanceSections = async (report: AssessmentReport, logContext: any) => {
    const buildSections: ReportDocSection[] = [];
    if (!isEmpty(report.eligibilities)) buildSections.push(ReportDocSection.Eligibility);
    if (!isEmpty(report.needGroups)) buildSections.push(ReportDocSection.Recommendations);
    const builtReport = await reduceAsyncSequence(
      buildSections,
      (section, report) => {
        runInAction(() => {
          if (this.buildResult) this.buildResult.status = `updating doc ${toHeaderString(section)}`;
        });
        return this.reportDocumentApi.buildSection(report.id, section);
      },
      report,
      {
        onSuccess: section => this.sectionBuildFinished(section, logContext),
        onFailure: (section, error) => this.sectionBuildFinished(section, logContext, error),
      },
    );
    this.setReport(builtReport);
    return builtReport;
  };
}
